import React from "react"

import { Container } from 'reactstrap' 
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from '../components/slider-howis' 
import truckAbsolute from '../assets/images/how.png' 
import worldIcon from '../assets/images/world.png' 
 
export default () => (
  <Layout>
    <SEO title="How's our Driver" />
    <Slider title="HOW'S OUR DRIVING ?"/>
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
					<h4 className="surpassing"><b>SURPASSING</b> ALL BOUNDARIES FROM <b>US</b> TO <b>YOU</b></h4> 
			  </div>
			</div>
		</Container>
	</div>
	
	
	<div className="point-crossed-background2" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<h4 className="credtiH"><b>HOW'S OUR DRIVING? </b></h4>   
				<img className="truckAbsolute3" src={truckAbsolute} alt="worldIcon"/>
			  </div> 
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12"> 
				<div  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 whiteBack"> 
					<p className="grayP">{'We care about the performance of our drivers on the road.'}</p> 
					<p className="grayText2">Send us an email <a href='mailto:info@gutierreztrucking.com'>info@gutierreztrucking.com</a></p>
				</div>
			  </div> 
			</div>
		</Container>
	</div>  
  </Layout>
)
